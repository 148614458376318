<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-6">
                    <h1>Вход в админку</h1>
                    <br />
                    <form id="form">
                        <fieldset>
                            <legend>Авторизуйтесь</legend>
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <label for="user">Логин</label>
                                    <input type="text" id="user" name="user" placeholder="Имя пользователя"/>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label for="password">Пароль</label>
                                    <input type="password" id="password" name="password" placeholder="Пароль"/>
                                </div>
                            </div>
                            <div class="row">
                                <mark id="error" class="inline-block secondary hidden" style="margin: 10px 0 10px 8px;">Текст ошибки</mark>
                            </div>
                            <div class="row">
                                <button class="primary">Войти в админку</button>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div class="col-sm-3"></div>
            </div>
        </div>

<!--

        <form id="LoginItem" class="LoginItem" @submit.prevent="login">
            <h1>Sign in</h1>
            <label>Email</label>
            <input required v-model="email" type="email" placeholder="Name" />
            <label>Password</label>
            <input required v-model="password" type="password" placeholder="Password" />
            <hr />
            <button type="submit">Login</button>
        </form>
    </div>
-->
</div>
</template>

<script>
export default {
    
    data() {
        return {
            email: "",
            password: ""
        }
    },

    methods: {
        login: function () {
            let email = this.email
            let password = this.password
            this.$store.dispatch('login', { email, password })
                .then(() => this.$router.push('/'))
                .catch(err => console.log(err))
        }
    }


}
</script>