<template>
  <header :class="{ 'scrolled-nav': scrolledNav }">
    <div
      style="
        max-width: 800px;
        overflow: hidden;
        background-color: white;
        text-align: left;
      "
    >
      <div class="row">
        <!--
        <button
          onclick = "window.location.assign('/products')"
          style="
            border: none;
            padding: 0px;
            background-color: white;
            position: relative;
          "
        >
        -->
        <button
          @click="this.getProd"
          style="
            border: none;
            padding: 0px;
            background-color: white;
            position: relative;
          "
        >
          <img src="../assets/images/logo_n2.webp" width="95%" />
        </button>
      </div>
    </div>
    <div class="row">
      <!--  <div class="col-5" style="text-align: right">
          <img src="../assets/images/phone.png" style="width: 32px" />
        </div>
       
        <div
          class="col-5"
          style="text-align: left; align-content: center; padding-left: 0px"
        >
          <span
            style="
              font: 'Ubuntu Mono';
              font-size: 12px;
              font-weight: 600;
              padding-top: 5px;
              text-align: left;
              padding-left: 0px;
            "
            >+7(962)004-18-90</span
          >
        </div>
        -->
      <br /><br />
      <div
        class="row"
        style="align-items: center; text-align: center; align-content: center"
      >
        <nav
          class="navigation"
          style="text-align: center; align-content: center"
        >
        <div v-if="this.isMobNav">
<ul
              class="vertical-menu"
              style="
                padding-left: 10px;
                font: 'Ubuntu Mono';
text-align: center;
                align-content: center;
                border-radius: 5px;
              "
            >
              <!--<li><router-link class="link" active-class="active" to="/" style="font-size: 18px; font-weight: 700;">Главная</router-link></li>-->
              <li>
                <router-link
                  class="link"
                  to="/products"
                  style="
                    font: 'Ubuntu Mono';
                    font-size: 16px;
                    font-weight: 500;
                    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
                      0 2px 4px -1px rgba(0, 0, 0, 0.06);
text-align: center;
                align-content: center;
                display: inline-block;
                margin: auto;
"
                  >Продукция</router-link
                >
              </li>
              <li>
                <router-link
                  class="link"
                  to="/delivery"
                  style="font: 'Ubuntu Mono'; font-size: 16px; font-weight: 500;
                    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
                      0 2px 4px -1px rgba(0, 0, 0, 0.06);"
                  >Оплата и доставка</router-link
                >
              </li>
              <li>
                <router-link
                  class="link"
                  to="/cart"
                  style="font: 'Ubuntu Mono'; font-size: 16px; font-weight: 500;
                    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
                      0 2px 4px -1px rgba(0, 0, 0, 0.06);"
                  >Корзина</router-link
                >
              </li>
              <li>
                <router-link
                  class="link"
                  to="/message"
                  style="font: 'Ubuntu Mono'; font-size: 16px; font-weight: 500;
                    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
                      0 2px 4px -1px rgba(0, 0, 0, 0.06);"
                  >Напишите нам</router-link
                >
              </li>
            </ul>
          </div>
          <div v-else>
          <ul
        
            style="
              padding-left: 10px;
              font: 'Ubuntu Mono';
              text-align: center;
              align-content: center;
              border-radius: 5px;
              display: flex;
              justify-content: center;
            "
          >
            <!--<li><router-link class="link" active-class="active" to="/" style="font-size: 18px; font-weight: 700;">Главная</router-link></li>-->
            <li>
              <a
                href="/products"
                class="link"
                style="
                  font: 'Ubuntu Mono';
                  font-size: 16px;
                  font-weight: 500;
                  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
                    0 2px 4px -1px rgba(0, 0, 0, 0.06);
                  text-align: center;
                  align-content: center;
                  display: block;
                  margin: auto;

                  width: 150px;
                  border-radius: 5px;
                  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 5px 0px;
                "
              >
                Продукция
              </a>
            </li>
            <li>
              <router-link
                class="link"
                to="/delivery"
                style="
                  font: 'Ubuntu Mono';
                  font-size: 16px;
                  font-weight: 500;
                  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
                    0 2px 4px -1px rgba(0, 0, 0, 0.06);
                  text-align: center;
                  align-content: center;
                  display: block;
                  margin: auto;

                  width: 150px;
                  border-radius: 5px;
                  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 5px 0px;
                "
                >Оплата и доставка</router-link
              >
            </li>
            <li>
              <router-link
                class="link"
                to="/cart"
                style="
                  font: 'Ubuntu Mono';
                  font-size: 16px;
                  font-weight: 500;
                  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
                    0 2px 4px -1px rgba(0, 0, 0, 0.06);
                  text-align: center;
                  align-content: center;
                  display: block;
                  margin: auto;

                  width: 150px;
                  border-radius: 5px;
                  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 5px 0px;
                "
                >Корзина</router-link
              >
            </li>
            <li>
              <router-link
                class="link"
                to="/message"
                style="
                  font: 'Ubuntu Mono';
                  font-size: 16px;
                  font-weight: 500;
                  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
                    0 2px 4px -1px rgba(0, 0, 0, 0.06);
                  text-align: center;
                  align-content: center;
                  display: block;
                  margin: auto;

                  width: 150px;
                  border-radius: 5px;
                  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 5px 0px;
                "
                >Напишите нам</router-link
              >
            </li>
          </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
//import router from '@/router';

export default {
  name: "NaivgationBar",

  data() {
    return {
      scrolledNav: null,
      isMobNav: null,
      mobileNav: null,

      scrollPosition: null,
    };
  },

  created() {
    //console.log(useCheckScreen().isMob._value);
    //this.isMobNav = this.chScreen;
    //this.isMobNav = this.chScreen;

    //this.mobileNav =  !this.chScreen;
    //const element = document.querySelector('nav')
    this.mobileNav = !this.chScreen;

    window.addEventListener("load", this.chScreen);
    window.addEventListener("resize", this.chScreen);
  },
  mounted() {
    this.isMobNav = this.chScreen;
    console.log("mounted....");
    //window.addEventListener('load', chScreen);
    //window.addEventListener("resize", chScreen);
    //window.addEventListener("scroll", this.updateScroll);
    //  window.addEventListener("resize", useCheckScreen);
  },
  methods: {
    getProd() {
      console.log(this.$router);
      console.log(this.$router.currentRoute.value);
      this.$router.push("/products");
      //this.$router.go();
      //window.href('/products');
    },
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    CloseMobileNav() {
      this.mobileNav = false;
    },

    updateScroll() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition > 30) {
        this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;
    },

    chScreen() {
      if (window.innerWidth <= 750) {
        this.isMobNav = true;
        this.colm = "col-8";
        this.cold = "col-2";
      } else {
        this.isMobNav = false;
        this.colm = "col-4";
        this.cold = "col-6";
      }
      return this.isMobNav;
    },
  },

  /*,watch: {
    '$route' () {
      if (window.innerWidth <= 750) {
      
      this.isMobNav = true;
    }
    else {
      this.isMobNav = false;
    }
  },
  */
};
</script>

<style lang="scss" scoped>
header {
  background-color: white;
  border-radius: 5px;
  //z-index: 99;
  width: 100%;
  max-width: 800px;
  position: relative;
  transition: 0.5s ease all;
  color: black;

  nav {
    position: relative;
    display: inline;
    flex-direction: row;
    background-color: white;
    transition: 0.5s ease all;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 1140px) {
      max-width: 1140px;
    }

    ul,
    .link {
      font-weight: 400;
      color: black;
      list-style: none;
      text-decoration: none;
    }

    li {
      //text-transform: uppercase;
      padding: 5px;
      // margin-left: 16px;
    }

    .link {
      font-size: 14px;
      transition: 0.5s ease all;
      padding-bottom: 4px;
      border-bottom: 1px solid transparent;

      &:hover {
        color: black;

        border-color: lightblue;
      }
    }

    .branding {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        transition: 0.5s ease all;
      }
    }

    .navigation {
      display: inline;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      margin: auto;
      padding: 0px;
    }

    .sticky {
      position: fixed;
      top: 0;
    }

    .icon {
      display: flex;
      align-items: right;
      position: absolute;
      top: 0;
      right: 50px;
      height: 100%;
      padding: 10px;
    }

    i {
      cursor: pointer;
      font-size: 14px;
      transition: 0.8s ease all;
    }

    .icon-active {
      transform: rotate(180deg);
    }

    .dropdown-nav {
      display: flex;
      flex-direction: column;
      position: fixed;
      width: 100%;
      max-width: 150px;
      height: 100%;
      background-color: white;
      top: 0;
      left: 0;

      li {
        margin-left: 0;

        .link {
          color: black;
        }
      }
    }
  }

  .isMobNav-nav-enter-active,
  .isMobNav-nav-leave-active {
    transition: 1s ease all;
  }

  .isMobNav-nav-enter-from,
  .isMobNav-nav-leave-to {
    transform: translateX(150px);
  }

  .isMobNav-nav-enter-to {
    transform: translateX(0);
  }
}

.scrolled-nav {
  background-color: lightblue;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  nav {
    padding: 0px;

    .branding {
      img {
        width: 40px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }
    }
  }

  .overlayX {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    bottom: 0;
    background: rgba(115, 113, 252, 0.8);
    z-index: 9998;
    transition: opacity 0.4s, width 0s 0.4s;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    opacity: 1;
  }

  .overlay_active {
    opacity: 1;
    width: 100%;
    transition: opacity 0.4s, width 0s 0s;
  }

  .block {
    position: relative;
  }

  .cartButton {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 72px;
    text-align: center;
    transition: all 0.3s ease-in-out;
  }

  .svg1 {
    margin-right: 3px;
    width: 40px;
    fill: #000;
    // noselect
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */
  }

  .quantity {
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 50px;
    background-color: white;
    width: 20px;
    color: #fff;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;
    // noselect
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */
  }

  .sticky {
    //position: -webkit-sticky;
    position: fixed;
    top: 0;
  }
}
</style>
